import {t} from "i18next";
import {fetchWithMessages} from "./fetchWithMessages";

export const removeEntityInBackend = async (
  endpoint,
  entityId,
  projectId,
  entityList,
  removeEntityAction,
  hasReturnValue = false
) => {
  const returnValue = await fetchWithMessages(
    projectId ? `projects/${projectId}/${endpoint}/${entityId}` : `${endpoint}/${entityId}`,
    {method: "DELETE", noResponseData: !hasReturnValue},
    {
      200: t("common:deletionSuccessful"),
      405: {type: "warning", message: true},
    }
  );
  // return the new list without the deleted element
  const newEntityList = entityList.filter((s) => s._id !== entityId);
  await removeEntityAction(newEntityList, returnValue);

  return returnValue;
};
