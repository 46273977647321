import {getSessionSubscription} from "@utils/registrationsUtilities";
import {searchInObjectsList} from "@shared/utils/searchInObjectsList";
import {personName} from "@shared/utils/utilities";
import {filterSessionsByCategories} from "./filterSessionsByCategories";
import {AgendaParamsProps} from "../agenda/useAgendaParams";
import {filterSessionsByCustomFields} from "./filterSessionsByCustomFields";

/**
 * Filters appointments based on search bar value, categories filter, and registrations.
 * @param {Array} slots - The array of slots to filter.
 * @param {Object} searchBarValue - The search bar value object containing text and scopes for filtering.
 * @param {Array} categoriesFilter - The array of categories to filter appointments.
 * @param {Array} customFieldsFilters -The  array of custom fields filters
 * @param {Array} registrations - The array of registrations to filter appointments by user.
 * @param {Array} customFieldsFilters -The  array of custom fields filters * @returns {Array} - The filtered array of appointments.
 */
export const filterAppointments = (
  {slots, registrations},
  {searchBarValue, categoriesFilter, customFieldsFilters}: AgendaParamsProps
) => {
  const {text: searchBarText = "", scopes = []} = searchBarValue;

  // Extract search scopes
  const searchInScope = Object.fromEntries(scopes.map((scope) => [scope, true]));

  // Filter slots by categories
  slots = filterSessionsByCategories(slots, categoriesFilter);
  slots = filterSessionsByCustomFields(slots, customFieldsFilters, registrations);

  // Search in the list of appointments based on specified search scopes
  return searchInObjectsList(searchBarText, slots, (slot) => [
    searchInScope.session && slot.session.name,
    searchInScope.activity && slot.session.activity.name,
    searchInScope.category && slot.session.activity.category.name,
    ...((searchInScope.secondaryCategories && slot.session.activity.secondaryCategories) || []),
    ...((searchInScope.stewards && slot.session.stewards?.map(personName)) || []),
    ...((searchInScope.places && slot.session.places?.map((p) => p.name)) || []),
    ...((searchInScope.sessionTags && slot.sessionTags) || []),
    ...((searchInScope.activityTags && slot.activityTags) || []),
    ...((searchInScope.registrations &&
      registrations
        ?.filter((r) => getSessionSubscription(r, slot.session))
        .map((r) => personName(r.user))) ||
      []),
    searchInScope.team && slot.session.team?.name,
  ]);
};
