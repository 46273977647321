import {createSlice} from "@reduxjs/toolkit";
import {sessionsActions} from "./sessions";
import {activitiesActions} from "./activities";
import {OFFLINE_MODE} from "@shared/utils/offlineModeUtilities";
import {createCustomEntityAdapter} from "@shared/utils/api/customEntityAdapter";
import {persistEntityInBackend} from "@shared/utils/api/persistEntityInBackend";
import {removeEntityInBackend} from "@shared/utils/api/removeEntityInBackend";
import {loadEntityFromBackend} from "@shared/utils/api/loadEntityFromBackend";
import {loadListFromBackend} from "@shared/utils/api/loadListFromBackend";
import {fetchWithMessages} from "@shared/utils/api/fetchWithMessages";
import {
  resetDependenciesContext,
  shouldAutoRefreshDependencies,
} from "@utils/features/featuresUtilities";
import {EntitiesSelectors, LoadListParams} from "@utils/features/types";
import {sorter} from "@shared/utils/sorters";

const stewardsAdapter = createCustomEntityAdapter({
  selectId: (el) => el._id,
  sortComparer: (a, b) => sorter.text(a.lastName, b.lastName),
});

export const stewardsSlice = createSlice({
  name: "stewards",
  initialState: stewardsAdapter.getInitialState({
    init: {status: "idle"},
    editing: {},
  }),
  reducers: stewardsAdapter.reducers,
});

const asyncActions = {
  loadList:
    ({forceLoad, silent}: LoadListParams = {}) =>
    async (dispatch, getState) => {
      const state = getState();
      const projectId = state.currentProject.project._id;

      await loadListFromBackend(
        "stewards",
        projectId,
        state.stewards.init,
        () => dispatch(stewardsActions.initContext(projectId)),
        (data) => {
          dispatch(stewardsActions.initList({list: data, project: projectId}));
          // If there were some new changes (using forceLoad), also force update of dependencies
          shouldAutoRefreshDependencies(forceLoad, data) &&
            resetDependenciesContext(dispatch, sessionsActions, activitiesActions);
        },
        forceLoad,
        !silent
      );
    },
  loadEditing: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    return loadEntityFromBackend(
      "stewards",
      entityId,
      projectId,
      state.stewards.editing,
      () => dispatch(stewardsActions.setEditing({_id: "new"})),
      (data) => dispatch(stewardsActions.setEditing(data)),
      {
        notFoundAction: () =>
          OFFLINE_MODE && dispatch(stewardsActions.setEditing(state.stewards.entities[entityId])),
      }
    );
  },
  persist: (fieldsToUpdate?: any) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id || fieldsToUpdate.project; // If no project id, fll back on the fields given

    // If some fields are given as argument, directly take this to update the registration
    const payload = fieldsToUpdate || state.stewards.editing;

    return persistEntityInBackend(
      "stewards",
      {...payload, project: projectId},
      projectId,
      (data) => dispatch(stewardsActions.addToList(data)),
      (data) => {
        dispatch(stewardsActions.updateInList(data));
        // Update all dependencies next time
        resetDependenciesContext(dispatch, sessionsActions, activitiesActions);
      }
    );
  },
  remove: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    await removeEntityInBackend(
      "stewards",
      entityId,
      projectId,
      stewardsSelectors.selectList(state),
      () => dispatch(stewardsActions.removeFromList(entityId))
    );
  },
  loadEditingHistory: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    !OFFLINE_MODE &&
      (await loadEntityFromBackend(
        "stewards",
        `${entityId}/history`,
        projectId,
        state.stewards.editing,
        null,
        (data) => dispatch(stewardsActions.changeEditing({history: data}))
      ));
  },
  getPdfPlanning: (id) => async (dispatch, getState) => {
    const state = getState();

    id = id || state.stewards.editing._id;
    try {
      return await fetchWithMessages(
        `projects/${state.currentProject.project._id}/pdf/stewardPlanning/${id}`,
        {isBlob: true, method: "GET"},
        {},
        false,
        true,
        id === "all" ? 7000 : 0
      );
    } catch {
      return Promise.reject();
    }
  },
};

const stewardsAdapterSelectors = stewardsAdapter.getSelectors((state) => state.stewards);

export const stewardsSelectors: EntitiesSelectors<any, any> = {
  selectEditing: (state) => state.stewards.editing,
  selectList: stewardsAdapterSelectors.selectAll,
  selectById: stewardsAdapterSelectors.selectEntities,
  selectIsLoaded: (state) => state.stewards.init.status === "loaded",
};

export const stewardsReducer = stewardsSlice.reducer;

export const stewardsActions = {
  ...stewardsSlice.actions,
  ...asyncActions,
};
