import {specialCategoriesFilterOptions} from "@features/sessions";
import {getVolunteeringCoefficient} from "@shared/utils/sessionsUtilities";
import {getSessionFromSlotOrSession} from "./getSessionFromSlotOrSession";

const sessionIsSelectedByCategory = (
  session,
  filters: {
    filterVolunteering: boolean,
    filterAllTheRest: boolean,
    pureCategoriesFilter: Array<string>,
  }
) => {
  const {filterVolunteering, filterAllTheRest, pureCategoriesFilter} = filters;

  // Filter by "special categories"
  let specialCategoriesFilter;
  if (filterAllTheRest && !filterVolunteering) {
    // "- All the rest -" is toggled but not "- All the volunteering -"
    specialCategoriesFilter = getVolunteeringCoefficient(session) === 0;
  } else if (filterVolunteering && !filterAllTheRest) {
    // "- All the volunteering -" is toggled but not "- All the rest -"
    specialCategoriesFilter = getVolunteeringCoefficient(session) > 0;
  } else if (filterVolunteering && filterAllTheRest) {
    // Both are toggled - if  we have both volunteering and allTheRest, it's like we don't filter at all
    specialCategoriesFilter = true;
  } else {
    // None are toggled - if we have none, then we don't wanna filter with it at all
    specialCategoriesFilter = false;
  }

  return (
    specialCategoriesFilter ||
    (pureCategoriesFilter.length > 0
      ? pureCategoriesFilter.includes(session.activity.category._id)
      : false) // Don't filter if there are no pure categories selected
  );
};

/**
 * Filters sessions or slots based on specified categories filter.
 * @param {Array} slotsOrSessions - The array of sessions or slots to filter.
 * @param {Array} categoriesFilter - The array of categories to filter sessions or slots.
 * @returns {Array} - The filtered array of sessions or slots.
 */
export const filterSessionsByCategories = (slotsOrSessions, categoriesFilter) => {
  if (categoriesFilter?.length > 0) {
    // Then filter only by category so we need to remove the special categories entries from the rest
    const filterVolunteering = categoriesFilter.includes("volunteering");
    const filterAllTheRest = categoriesFilter.includes("allTheRest");
    const pureCategoriesFilter = categoriesFilter.filter(
      (category) => !specialCategoriesFilterOptions.includes(category)
    );

    slotsOrSessions = slotsOrSessions?.filter((item) => {
      const session = getSessionFromSlotOrSession(item);

      return sessionIsSelectedByCategory(session, {
        filterVolunteering,
        filterAllTheRest,
        pureCategoriesFilter,
      });
    });
  }

  return slotsOrSessions;
};
