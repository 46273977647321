import dayjs, {Dayjs, dayjsBase, PROJECT_TIMEZONE} from "@shared/services/dayjs";
import i18next, {t} from "i18next";

type DateToFormat = Dayjs | string;

// Whether to display the time in the project's time zone (always the same no matter the user's location),
// or display the time in the user's time zone (the time will change based on the user's location).
type DisplayTimeZoneOptions = {displayInTimeZone?: "project" | "user"};

type DisplayFormatOptions = {short?: boolean; withYear?: boolean};

type FullDisplayOptions = DisplayTimeZoneOptions & DisplayFormatOptions;

export const timeFormatter = {
  /**
   * Formats a date to a time string in the local format.
   *
   * Examples:
   * - displayInTimeZone: "project" (UTC+2) -> "14:30"
   * - displayInTimeZone: "user" (UTC+6) -> "18:30"
   */
  time: (date: DateToFormat, {displayInTimeZone = "project"}: DisplayTimeZoneOptions = {}) => {
    const dayjsInstance = displayInTimeZone === "project" ? dayjs : dayjsBase;

    return date ? dayjsInstance(date).format("LT") : "";
  },

  /**
   * Formats a time range in the local format.
   *
   * Examples:
   * - displayInTimeZone: "project" (UTC+2) -> "14:30 – 16:00"
   * - displayInTimeZone: "user" (UTC+6) -> "18:30 – 20:00"
   */
  timeRange: (
    startDate: DateToFormat,
    endDate: DateToFormat,
    displayOptions: DisplayTimeZoneOptions = {}
  ) =>
    startDate && endDate
      ? timeFormatter.time(startDate, displayOptions) +
        " – " +
        timeFormatter.time(endDate, displayOptions)
      : "",

  /**
   * Formats a duration in minutes to a readable text.
   *
   * Examples:
   * - short: false -> "2 days and 3h30 (3150min)"
   * - short: true -> "2 days and 3h30"
   */
  duration: (minutes: number, {short = false}: Pick<DisplayFormatOptions, "short"> = {}) => {
    if (minutes < 60) return t("common:time.xMin", {count: Math.floor(minutes)});
    const hours = dayjsBase()
      .startOf("day")
      .add(minutes, "minute")
      .format(t("common:time.hoursDurationFormat") as string);
    const numberOfDays = Math.floor(minutes / 1440);

    const duration =
      numberOfDays > 0 ? t("common:time.xDaysAndHours", {days: numberOfDays, hours}) : hours;

    return duration + (short ? "" : ` (${t("common:time.xMin", {count: Math.floor(minutes)})})`);
  },
};

export const dateFormatter = {
  /**
   * Formats a date to a short date string.
   *
   * Examples:
   * - displayInTimeZone: "project" (UTC+2) -> "15/07"
   * - displayInTimeZone: "user" (UTC+6) -> "15/07"
   */
  shortDate: (date: DateToFormat, {displayInTimeZone = "project"}: DisplayTimeZoneOptions = {}) => {
    if (!date) return "";
    const parsedDate = Date.parse(dayjsBase(date).toISOString());

    return Intl.DateTimeFormat(i18next.language, {
      day: "numeric",
      month: "numeric",
      timeZone: displayInTimeZone === "project" ? PROJECT_TIMEZONE : undefined,
    }).format(parsedDate);
  },

  /**
   * Formats a date to a long date string.
   *
   * Examples:
   * - short: false, withYear: false, displayInTimeZone: "project" (UTC+2) -> "Saturday, July 15"
   * - short: true, withYear: true, displayInTimeZone: "user" (UTC+6) -> "Sat, Jul 15, 2023"
   */
  longDate: (
    date: DateToFormat,
    {short = false, withYear = false, displayInTimeZone = "project"}: FullDisplayOptions = {}
  ) => {
    if (!date) return "";
    const parsedDate = Date.parse(dayjsBase(date).toISOString());

    return Intl.DateTimeFormat(i18next.language, {
      day: "numeric",
      weekday: short ? "short" : "long",
      month: short ? "short" : "long",
      year: withYear ? "numeric" : undefined,
      timeZone: displayInTimeZone === "project" ? PROJECT_TIMEZONE : undefined,
    }).format(parsedDate);
  },

  /**
   * Formats a date to a long date and time string.
   *
   * Examples:
   * - displayInTimeZone: "project" (UTC+2) -> "Saturday, July 15 14:30"
   * - displayInTimeZone: "user" (UTC+6) -> "Saturday, July 15 18:30"
   */
  longDateTime: (date: DateToFormat, displayOptions: FullDisplayOptions = {}) => {
    if (!date) return "";

    const dateString = dateFormatter.longDate(date, displayOptions);
    const timeString = timeFormatter.time(date, displayOptions);
    return dateString + " " + timeString;
  },

  /**
   * Formats a date range to a long date range string.
   *
   * Examples:
   * - displayInTimeZone: "project" (UTC+2) -> "Saturday, July 15 – Sunday, July 16, 2023"
   * - displayInTimeZone: "user" (UTC+6) -> "Saturday, July 15 – Sunday, July 16, 2023"
   */
  longDateRange: (
    startDate: DateToFormat,
    endDate: DateToFormat,
    displayOptions: FullDisplayOptions = {}
  ) => {
    if (!startDate || !endDate) return "";

    // Don't display year on start string, cause it is redundant with end string
    const startString = dateFormatter.longDate(startDate, {...displayOptions, withYear: false});
    const endString = dateFormatter.longDate(endDate, displayOptions);
    return `${startString} – ${endString}`;
  },

  /**
   * Formats a date and time range to a long date and time range string.
   *
   * Examples:
   * - Same day, displayInTimeZone: "project", short: false, withYear: true -> "Saturday, July 15 2024 14:30 – 16:00"
   * - Different days, displayInTimeZone: "project", short: true, withYear: true -> "Sat, Jul 15 14:30 – Sun, Jul 16 2024 16:00"
   * - Different days, displayInTimeZone: "user", short: true, withYear: false -> "Sat, Jul 15 17:30 – Sun, Jul 16 19:00"
   */
  longDateTimeRange: (
    startDate: DateToFormat,
    endDate: DateToFormat,
    {displayInTimeZone = "project", ...displayOptions}: FullDisplayOptions = {}
  ) => {
    if (!startDate || !endDate) return "";

    const dayjsInstance = displayInTimeZone === "project" ? dayjs : dayjsBase;
    const isSameDay = dayjsInstance(startDate).isSame(dayjsInstance(endDate), "day");

    if (isSameDay) {
      // If same day, only display once the day at the beginning, then only the end time
      return `${dateFormatter.longDateTime(startDate, displayOptions)} – ${timeFormatter.time(
        endDate
      )}`;
    } else {
      // If not same day, display both days
      const startString = dateFormatter.longDateTime(startDate, {
        ...displayOptions,
        withYear: false,
      });
      const endString = dateFormatter.longDateTime(endDate, displayOptions);
      return `${startString} – ${endString}`;
    }
  },
};
