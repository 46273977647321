import {useLayoutEffect} from "react";
import {ConfigProvider} from "antd";
import {useSelector} from "react-redux";
import {viewSelectors} from "@features/view";
import {useThemeToken} from "../hooks/useThemeToken";
import {currentProjectSelectors} from "@features/currentProject";
import {useThemedAppMethods} from "../hooks/useThemedAppMethods";

const setCSSVariables = (variables) => {
  const htmlStyle = document.getElementsByTagName("html")[0].style;
  Object.entries(variables).forEach((v) =>
    v[1] !== null ? htmlStyle.setProperty(v[0], v[1]) : htmlStyle.removeProperty(v[0])
  );
};

const setCustomCss = (customCss) => {
  let customCssStyleElement = document.getElementById("customNoeCss");
  if (!customCssStyleElement) {
    customCssStyleElement = document.createElement("style");
    customCssStyleElement.id = "customNoeCss";
    document.head.appendChild(customCssStyleElement);
  }
  customCssStyleElement.innerHTML = customCss;
};

export const setAppTheme = ({primary, bg, accent1, accent2, customCss} = {}) => {
  setCSSVariables({
    "--noe-primary": primary || null,
    "--noe-bg": bg || null,
    "--noe-accent-1": accent1 || null,
    "--noe-accent-1-90": accent1 || null,
    "--noe-accent-1-85": accent1 || null,
    "--noe-accent-2": accent2 || null,
    "--noe-accent-2-85": accent2 || null,
  });

  setCustomCss(customCss);
};

export const useDarkModeTheme = () => {
  // Modify the html to add a light or dark mode class
  const darkMode = useSelector(viewSelectors.selectDarkMode);
  document.getElementsByTagName("html")[0].className = darkMode ? "dark-mode" : "light-mode";

  // Instantiate the message and notification methods with the theme
  useThemedAppMethods();
};

// Set up the CSS variables for the user theme
export const DynamicProjectThemeProvider = ({children}) => {
  const theme = useSelector((s) => currentProjectSelectors.selectProject(s).theme);
  const customCss = useSelector(
    (s) => currentProjectSelectors.selectProject(s).customisation?.customCss
  );

  const darkMode = useSelector(viewSelectors.selectDarkMode);
  const {colorBgElevated} = useThemeToken();

  useLayoutEffect(() => {
    if (theme) {
      setAppTheme({
        ...theme,
        bg: darkMode ? colorBgElevated : theme.bg, // in dark mode, override the bg color to the bg elevated color.
        customCss,
      });
    }
  }, [theme, customCss, darkMode, colorBgElevated]);

  const primaryColor = theme?.primary;

  return (
    <ConfigProvider
      theme={
        primaryColor && {
          token: {
            colorPrimary: primaryColor,
            colorLink: primaryColor,
          },
        }
      }>
      {children}
    </ConfigProvider>
  );
};
