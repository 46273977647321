import {combineReducers} from "@reduxjs/toolkit";
import {currentUserReducer} from "@features/currentUser";
import {currentProjectReducer} from "@features/currentProject";
import {projectsReducer} from "@features/projects";
import {registrationsReducer} from "@features/registrations";
import {sessionsReducer} from "@features/sessions";
import {teamsReducer} from "@features/teams";
import {placesReducer} from "@features/places";
import {stewardsReducer} from "@features/stewards";
import {activitiesReducer} from "@features/activities";
import {usersReducer} from "@features/users";
import {categoriesReducer} from "@features/categories";
import {viewReducer} from "@features/view";
import {getConfiguredStoreAndPersistor} from "@shared/utils/offlineModeUtilities";
import {getSentryReduxEnhancer} from "@shared/services/sentry";

const mainReducer = combineReducers({
  currentUser: currentUserReducer,
  currentProject: currentProjectReducer,
  projects: projectsReducer,
  registrations: registrationsReducer,
  sessions: sessionsReducer,
  teams: teamsReducer,
  places: placesReducer,
  stewards: stewardsReducer,
  activities: activitiesReducer,
  users: usersReducer,
  categories: categoriesReducer,
  view: viewReducer,
});

export const [store, persistor] = getConfiguredStoreAndPersistor(
  mainReducer,
  process.env.NODE_ENV === "production" ? [getSentryReduxEnhancer()] : undefined
);
