import {createSlice} from "@reduxjs/toolkit";
import {persistEntityInBackend} from "@shared/utils/api/persistEntityInBackend";
import {sessionsActions} from "./sessions";
import {registrationsActions} from "./registrations";
import {OFFLINE_MODE} from "@shared/utils/offlineModeUtilities";
import {createCustomEntityAdapter} from "@shared/utils/api/customEntityAdapter";
import {removeEntityInBackend} from "@shared/utils/api/removeEntityInBackend";
import {loadEntityFromBackend} from "@shared/utils/api/loadEntityFromBackend";
import {loadListFromBackend} from "@shared/utils/api/loadListFromBackend";
import {
  resetDependenciesContext,
  shouldAutoRefreshDependencies,
} from "@utils/features/featuresUtilities";
import {EntitiesSelectors, LoadListParams} from "@utils/features/types";
import {sorter} from "@shared/utils/sorters";

const reloadElementDependencies = (dispatch) => {
  // Update all dependencies next time
  dispatch(sessionsActions.loadList({forceLoad: true}));
  dispatch(registrationsActions.loadList({forceLoad: true}));
};

const teamsAdapter = createCustomEntityAdapter({
  selectId: (el) => el._id,
  sortComparer: (a, b) => sorter.text(a.name, b.name),
});

export const teamsSlice = createSlice({
  name: "teams",
  initialState: teamsAdapter.getInitialState({
    init: {status: "idle"},
    editing: {},
  }),
  reducers: teamsAdapter.reducers,
});

const asyncActions = {
  loadList:
    ({forceLoad, silent}: LoadListParams = {}) =>
    async (dispatch, getState) => {
      const state = getState();
      const projectId = state.currentProject.project._id;

      if (state.currentProject.project.useTeams) {
        await loadListFromBackend(
          "teams",
          projectId,
          state.teams.init,
          () => dispatch(teamsActions.initContext(projectId)),
          (data) => {
            dispatch(teamsActions.initList({list: data, project: projectId}));
            // If there were some new changes (using forceLoad), also force update of dependencies
            shouldAutoRefreshDependencies(forceLoad, data) &&
              resetDependenciesContext(dispatch, sessionsActions, registrationsActions);
          },
          forceLoad,
          !silent
        );
      }
    },
  loadEditing: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    return loadEntityFromBackend(
      "teams",
      entityId,
      projectId,
      state.teams.editing,
      () =>
        dispatch(
          teamsActions.setEditing({
            _id: "new",
            stewards: [],
            places: [],
          })
        ),
      (data) => dispatch(teamsActions.setEditing(data)),
      {
        notFoundAction: () =>
          OFFLINE_MODE && dispatch(teamsActions.setEditing(state.teams.entities[entityId])),
      }
    );
  },
  persist: (fieldsToUpdate?: any) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id || fieldsToUpdate.project; // If no project id, fll back on the fields given

    // If some fields are given as argument, directly take this to update the registration
    const payload = fieldsToUpdate || state.teams.editing;

    return persistEntityInBackend(
      "teams",
      {...payload, project: projectId},
      projectId,
      ({sessionsToUpdateIds, registrationsToUpdateIds, ...team}) => {
        dispatch(teamsActions.addToList(team));
        reloadElementDependencies(dispatch);
      },
      ({sessionsToUpdateIds, registrationsToUpdateIds, ...team}) => {
        dispatch(teamsActions.updateInList(team));
        reloadElementDependencies(dispatch);
      }
    );
  },
  remove: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    await removeEntityInBackend(
      "teams",
      entityId,
      projectId,
      teamsSelectors.selectList(state),
      () => {
        dispatch(teamsActions.removeFromList(entityId));
        reloadElementDependencies(dispatch);
      },
      true
    );
  },
  loadEditingHistory: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    !OFFLINE_MODE &&
      (await loadEntityFromBackend(
        "teams",
        `${entityId}/history`,
        projectId,
        state.teams.editing,
        null,
        (data) => dispatch(teamsActions.changeEditing({history: data}))
      ));
  },
};

const teamsAdapterSelectors = teamsAdapter.getSelectors((state) => state.teams);

export const teamsSelectors: EntitiesSelectors<any, any> = {
  selectEditing: (state) => state.teams.editing,
  selectList: teamsAdapterSelectors.selectAll,
  selectById: teamsAdapterSelectors.selectEntities,
  selectIsLoaded: (state) => state.teams.init.status === "loaded",
};

export const teamsReducer = teamsSlice.reducer;

export const teamsActions = {
  ...teamsSlice.actions,
  ...asyncActions,
};
