import {createSlice} from "@reduxjs/toolkit";
import {projectsActions} from "./projects.js";
import {registrationsActions} from "./registrations";
import {fetchWithMessages} from "@shared/utils/api/fetchWithMessages";

export const currentProjectSlice = createSlice({
  name: "currentProject",
  initialState: {
    project: {},
  },
  reducers: {
    clean: (state, action) => {
      state.project = {};
    },
    changeProject: (state, action) => {
      state.project = action.payload;
    },
  },
});

const asyncActions = {
  load: (projectId) => async (dispatch, getState) => {
    const state = getState();

    const currentProject = state.currentProject.project;
    const projectIsLoadedInPublicMode = currentProject.public;

    const currentUser = state.currentUser.user;
    const userIsConnected = !!currentUser?._id;

    if (
      projectId && // we must have an id, otherwise we can't load, that's necessary
      ((currentProject._id !== projectId && currentProject.slug !== projectId) || // if the project to load is not the same as the current project, it has to be loaded
        (projectIsLoadedInPublicMode && userIsConnected) || //Or... if it's loaded in public mode whereas the user is now connected, we have to reload it again also
        (!projectIsLoadedInPublicMode && !userIsConnected))
    ) {
      // If the user is connected, load the full project, else, just get public info about the project
      const project = await fetchWithMessages(
        userIsConnected ? `projects/${projectId}` : `projects/${projectId}/public`,
        {method: "GET"}
      );

      if (!project) return; // If fetch fails, don't go further

      project.public = !userIsConnected;
      await dispatch(projectsActions.changeEditing(project));
      dispatch(currentProjectActions.changeProject(project));
    }
  },
  cleanProject: () => async (dispatch, getState) => {
    await Promise.all([
      dispatch(currentProjectActions.clean()), // Clean the current project
      dispatch(projectsActions.setEditing({})), // Clean the project editing data
      dispatch(projectsActions.cleanProjectEntities()), // Clean all the lists of objects we previously loaded
      // also clean registration so it doesn't interfere
      dispatch(registrationsActions.setCurrent(undefined)),
    ]);
  },
};

export const currentProjectSelectors = {
  selectProject: (state) => state.currentProject.project,
};

export const currentProjectReducer = currentProjectSlice.reducer;

export const currentProjectActions = {
  ...currentProjectSlice.actions,
  ...asyncActions,
};
