import {Checkbox, CheckboxProps} from "antd";
import {CheckboxGroupProps as AntdCheckboxGroupProps} from "antd/es/checkbox";
import React, {ReactNode} from "react";
import {FormItem, FormItemProps} from "./FormItem";

type CheckboxOption = Omit<CheckboxProps, "children"> & {label: ReactNode};

type CheckboxGroupProps = Omit<AntdCheckboxGroupProps, "options"> & {
  options?: Array<CheckboxOption>;
};

export const CheckboxGroupComponent = ({options, ...props}: CheckboxGroupProps) => {
  return (
    <Checkbox.Group {...props}>
      <div className={"containerV"} style={{gap: 3}}>
        {options?.map((opt) => (
          <Checkbox {...opt} key={opt.value}>
            <span title={opt.title}>{opt.label}</span>
          </Checkbox>
        ))}
      </div>
    </Checkbox.Group>
  );
};

export const CheckboxGroupInput = (props: FormItemProps<CheckboxGroupProps>) => (
  <FormItem {...props}>
    <CheckboxGroupComponent />
  </FormItem>
);
