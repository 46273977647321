import dayjs from "@shared/services/dayjs";
import {filterSessionsByCategories} from "./filterSessionsByCategories";
import {filterSessionsByCustomFields} from "./filterSessionsByCustomFields";
import {AgendaParamsProps} from "../agenda/useAgendaParams";

/**
 * Filter by period functions. Each one allow to filter a part of the sessions list depending on the period
 */
const periodFilterFunctions = {
  hidePastSessions: (session) => dayjs().isBefore(session.end),
  showNextSessions: (session) => dayjs().isBefore(session.start),
  showOngoingSessions: (session) => dayjs().isBefore(session.end) && dayjs().isAfter(session.start),
  all: () => true,
};

/**
 * Filters sessions based on search bar value, categories filter, and filter period.
 * @param {Array} sessions - The array of sessions to filter.
 * @param {Array} registrations - The array of registrations to the project.
 * @param {Array} categoriesFilter - The array of categories to filter sessions.
 * @param {Array} customFieldsFilters
 * @param {string} filterPeriod - The filter period ("hidePastSessions", "showNextSessions", "showOngoingSessions", or "all").
 * @returns {Array} - The filtered array of sessions.
 */
export const filterSessions = (
  {sessions, registrations},
  {categoriesFilter, customFieldsFilters}: AgendaParamsProps,
  filterPeriod
) => {
  const filterByPeriod = periodFilterFunctions[filterPeriod] || periodFilterFunctions.all;

  // Filter sessions based on filter period
  let filteredSessions = sessions.filter(filterByPeriod);

  // Return sessions filtered by categories
  filteredSessions = filterSessionsByCategories(filteredSessions, categoriesFilter);
  filteredSessions = filterSessionsByCustomFields(
    filteredSessions,
    customFieldsFilters,
    registrations
  );

  return filteredSessions;
};
