import {createSlice} from "@reduxjs/toolkit";
import {sessionsActions} from "./sessions";
import {activitiesActions} from "./activities";
import {OFFLINE_MODE} from "@shared/utils/offlineModeUtilities";
import {createCustomEntityAdapter} from "@shared/utils/api/customEntityAdapter";
import {persistEntityInBackend} from "@shared/utils/api/persistEntityInBackend";
import {removeEntityInBackend} from "@shared/utils/api/removeEntityInBackend";
import {loadEntityFromBackend} from "@shared/utils/api/loadEntityFromBackend";
import {loadListFromBackend} from "@shared/utils/api/loadListFromBackend";
import {
  resetDependenciesContext,
  shouldAutoRefreshDependencies,
} from "@utils/features/featuresUtilities";
import {EntitiesSelectors, LoadListParams} from "@utils/features/types";
import {sorter} from "@shared/utils/sorters";

const placesAdapter = createCustomEntityAdapter({
  selectId: (el) => el._id,
  sortComparer: (a, b) => sorter.text(a.name, b.name),
});

export const placesSlice = createSlice({
  name: "places",
  initialState: placesAdapter.getInitialState({
    init: {status: "idle"},
    editing: {},
  }),
  reducers: placesAdapter.reducers,
});

const asyncActions = {
  loadList:
    ({forceLoad, silent}: LoadListParams = {}) =>
    async (dispatch, getState) => {
      const state = getState();
      const projectId = state.currentProject.project._id;

      if (state.currentProject.project.usePlaces) {
        await loadListFromBackend(
          "places",
          projectId,
          state.places.init,
          () => dispatch(placesActions.initContext(projectId)),
          (data) => {
            dispatch(placesActions.initList({list: data, project: projectId}));
            // If there were some new changes (using forceLoad), also force update of dependencies
            shouldAutoRefreshDependencies(forceLoad, data) &&
              resetDependenciesContext(dispatch, sessionsActions, activitiesActions);
          },
          forceLoad,
          !silent
        );
      }
    },
  loadEditing: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    return loadEntityFromBackend(
      "places",
      entityId,
      projectId,
      state.places.editing,
      () => dispatch(placesActions.setEditing({_id: "new"})),
      (data) => dispatch(placesActions.setEditing(data)),
      {
        notFoundAction: () =>
          OFFLINE_MODE && dispatch(placesActions.setEditing(state.places.entities[entityId])),
      }
    );
  },
  persist: (fieldsToUpdate?: any) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id || fieldsToUpdate.project; // If no project id, fll back on the fields given

    // If some fields are given as argument, directly take this to update the registration
    const payload = fieldsToUpdate || state.places.editing;

    return persistEntityInBackend(
      "places",
      {...payload, project: projectId},
      projectId,
      (data) => dispatch(placesActions.addToList(data)),
      (data) => {
        dispatch(placesActions.updateInList(data));
        resetDependenciesContext(dispatch, sessionsActions, activitiesActions);
      }
    );
  },
  remove: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    await removeEntityInBackend(
      "places",
      entityId,
      projectId,
      placesSelectors.selectList(state),
      () => dispatch(placesActions.removeFromList(entityId))
    );
  },
  loadEditingHistory: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    !OFFLINE_MODE &&
      (await loadEntityFromBackend(
        "places",
        `${entityId}/history`,
        projectId,
        state.places.editing,
        null,
        (data) => dispatch(placesActions.changeEditing({history: data}))
      ));
  },
};

const placesAdapterSelectors = placesAdapter.getSelectors((state) => state.places);

export const placesSelectors: EntitiesSelectors<any, any> = {
  selectEditing: (state) => state.places.editing,
  selectList: placesAdapterSelectors.selectAll,
  selectById: placesAdapterSelectors.selectEntities,
  selectIsLoaded: (state) => state.places.init.status === "loaded",
};

export const placesReducer = placesSlice.reducer;

export const placesActions = {
  ...placesSlice.actions,
  ...asyncActions,
};
