import {theme as antdTheme, ThemeConfig} from "antd";

const {darkAlgorithm} = antdTheme;

const darkThemeBase: ThemeConfig = {
  token: {
    colorPrimary: "#4479d7",
    colorLink: "#4479d7",
    borderRadius: 0,
    colorBgContainer: "#090909",
    colorBgElevated: "#1b1b1b",
    colorBgLayout: "#181818",
    colorBorder: "#4b4b4b",
    colorBorderSecondary: "#3d3d3d",
    wireframe: false,
    colorInfo: "#4479d7",
  },
  components: {
    Segmented: {
      itemSelectedBg: "#3a3a3a",
    },
  },
  algorithm: darkAlgorithm,
};

const lightThemeBase: ThemeConfig = {
  token: {
    colorPrimary: "#3775e1",
    colorLink: "#3775e1",
    colorTextBase: "#000a2e",
    borderRadius: 0,
    colorBgContainer: "#ffffff",
    colorBgElevated: "#fcfcfc",
    colorBgLayout: "#fafafa",
    colorBorder: "#bbbbbb",
    colorBorderSecondary: "#cecece",
    colorTextPlaceholder: "#707070",
    wireframe: false,
    fontFamily: "Montserrat, Helvetica, Arial, Lucida, sans-serif",
  },
  components: {
    Collapse: {
      colorFillAlter: "#f5f5f5",
    },
  },
};

const defaultComponentsTheme: ThemeConfig["components"] = {
  Card: {
    fontWeightStrong: 500,
  },
  Modal: {
    fontWeightStrong: 500,
    wireframe: true,
  },
  Drawer: {
    fontWeightStrong: 500,
  },
};

const buildTheme = (theme: ThemeConfig): ThemeConfig => ({
  ...theme,
  components: {
    ...defaultComponentsTheme,
    ...theme.components,
  },
});

export const LIGHT_THEME = buildTheme(lightThemeBase);
export const DARK_THEME = buildTheme(darkThemeBase);
