import * as serviceWorkerRegistration from "@shared/services/serviceWorkerRegistration";
import {t} from "i18next";
import {useEffect} from "react";
import {AppMethods} from "./useThemedAppMethods";
import {URLS} from "@app/configuration";

// Service worker registration - if an update is available, reload the page without asking the user at all.
// Not the best behavior, but this is the less complicated.
export const useRegisterServiceWorker = () => {
  useEffect(() => {
    // Only activate PWA if we are running on the canonical URL, not if the app is proxified somewhere
    if (window.location.href.includes(URLS.CURRENT)) {
      serviceWorkerRegistration.register({
        onUpdate: (registration: ServiceWorkerRegistration) => {
          AppMethods.message.loading(t("common:pwa.newVersionAvailable")).then(() => {
            try {
              console.log('registration.waiting.postMessage({type: "SKIP_WAITING"})');
              (registration.waiting as ServiceWorker | null)?.postMessage({
                type: "SKIP_WAITING",
              });
              console.log("Attempting reload...");
              window.location.reload();
            } catch (e) {
              console.error("Huhooo error : ", e);
              AppMethods.message.warning(t("common:pwa.reloadFailedPleaseCloseTabs"));
            }
          });
        },
      });
    }
  }, []);
};
