import dayjs, {dayjsBase, guessedUserTimezone, PROJECT_TIMEZONE} from "@shared/services/dayjs";

export const getTimezoneOffsetDifferenceInHours = () => {
  const projectDateTime = dayjs();
  const browserDateTime = dayjsBase().tz(guessedUserTimezone);

  return (browserDateTime.utcOffset() - projectDateTime.utcOffset()) / 60;
};

/**
 * Those calculations are expensive, maybe we could find a better way to make a timezone diff manager.
 */
export const timezoneDiffManager = {
  keepDateFormat: {
    set: (date) => dayjsBase(date).tz(PROJECT_TIMEZONE).tz(guessedUserTimezone, true),
    unset: (date) => dayjsBase(date).tz(guessedUserTimezone).tz(PROJECT_TIMEZONE, true),
  },
  set: (date) => timezoneDiffManager.keepDateFormat.set(date).toISOString(),
  unset: (date) => timezoneDiffManager.keepDateFormat.unset(date).toISOString(),
};
