import {getSessionElementsFromListInSlot, slotNumberString} from "@utils/agendaUtilities";
import dayjs from "@shared/services/dayjs";
import {getFullSessionName} from "@shared/utils/sessionsUtilities";

import {dateFormatter} from "@shared/utils/formatters";

export const getSessionsPlanningForRegistration = (
  sessionsSubscriptions,
  allSlots,
  teams,
  sessionNameTemplate,
  allPlanningColumns?
) =>
  sessionsSubscriptions
    .map((ss) => {
      const slot = allSlots.find((slot) => slot.session._id === ss.session);
      if (!slot) return;
      const title =
        slotNumberString(slot) + getFullSessionName(slot.session, sessionNameTemplate, teams);
      const location = getSessionElementsFromListInSlot("places", slot, (el) => el.name).join(", ");
      const dateTimeRange = dateFormatter.longDateTimeRange(slot.start, slot.end, {short: true});
      const categoryName = slot.session.activity?.category.name;

      // Add the slot date to the dates columns if needed
      allPlanningColumns && allPlanningColumns.push(dayjs(slot.start));

      return {
        startDate: slot.start,
        endDate: slot.end,
        sessionId: slot.session._id,
        value: `${dateTimeRange} : [${categoryName}] ${title} (${location})`,
      };
    })
    .filter((slotInfo) => slotInfo !== undefined)
    .sort((a, b) => dayjs(a.startDate).diff(b.startDate));
