import {slotEndIsBeforeBeginning} from "./slotsUtilities";
import {t} from "i18next";
import {shouldShowField} from "@shared/components/FormRenderer/shouldShowField";
import {truncate} from "@shared/utils/stringUtilities";
import dayjs from "@shared/services/dayjs";
import {getSessionsPlanningForRegistration} from "@routes/participants/atoms/getSessionsPlanningForRegistration";
import {flattenFieldComponents} from "@shared/utils/flattenFieldComponents";

export const getRegistrationMetadata = (registration, project) => {
  const data = {};
  if (registration) {
    // Availabilities are clean
    const firstSlot = registration.availabilitySlots?.[0];
    const firstSlotIsDefined = !!(firstSlot?.start && firstSlot?.end);
    data.firstSlotEndIsBeforeBeginning = firstSlotIsDefined && slotEndIsBeforeBeginning(firstSlot);
    data.firstSlotIsOk = firstSlotIsDefined && !data.firstSlotEndIsBeforeBeginning;

    data.datesAlert =
      registration.booked && !firstSlotIsDefined
        ? t("registrations:registrationValidation.selectDatesToRegister")
        : data.firstSlotEndIsBeforeBeginning
        ? t("registrations:registrationValidation.endCannotBeBeforeStart")
        : undefined;

    // All mandatory form fields are filled
    const unfulfilledMandatoryFormFields = [];
    const flatFormComponents = flattenFieldComponents(project.formComponents);
    for (let formComp of flatFormComponents) {
      if (formComp.required) {
        const value = registration.formAnswers?.[formComp.key];

        // Check if the field should be shown before validating
        if (
          shouldShowField(
            formComp.conditional,
            flatFormComponents,
            registration.formAnswers?.[formComp.conditional?.when]
          )
        ) {
          let invalid = false;

          if (
            // No value at all
            value === undefined ||
            value === null ||
            // Checkbox not checked
            value === false ||
            // Empty text
            value === "" ||
            // Empty array
            (Array.isArray(value) && value.length === 0)
          ) {
            invalid = true;
          } else if (Array.isArray(value)) {
            // Checkbox group / Multiselect number of selected elements
            const {min, max} = formComp.selectedCount || {};
            if ((min && value.length < min) || (max && value.length > max)) {
              invalid = true;
            }
          } else if (typeof value === "number") {
            // Validate number input
            const {min, max} = formComp.minMaxNumber || {};
            if ((min && value < min) || (max && value > max)) {
              invalid = true;
            }
          }

          // Add to unfulfilled fields only if the field is invalid and visible
          if (invalid) {
            unfulfilledMandatoryFormFields.push(formComp.label);
          }
        }
        // If the field is not visible, it's considered valid in any case, even if required
      }
    }
    data.formIsOk = unfulfilledMandatoryFormFields.length === 0;
    data.formAlert =
      registration.booked && !data.formIsOk
        ? t("registrations:registrationValidation.someFormFieldsAreMandatory", {
            list: unfulfilledMandatoryFormFields.map((field) => truncate(field, 30)).join('", "'),
          })
        : undefined;

    // Mandatory Ticketing
    data.ticketingIsOk =
      !project.ticketingMode || registration?.[`${project.ticketingMode}Tickets`]?.length > 0;
    data.ticketingAlert =
      registration.booked && !data.ticketingIsOk
        ? t("registrations:registrationValidation.ticketingIsMandatory")
        : undefined;

    data.everythingIsOk = data.firstSlotIsOk && data.formIsOk && data.ticketingIsOk;

    return data;
  }
};

export const getInitializedRegistration = (registration, project, initializeDatabaseMetadata) => {
  const validationFields = getRegistrationMetadata(registration, project);
  const newRegistration = {
    ...registration,
    ...validationFields, // instant validation fields, always up to date
    touched: !initializeDatabaseMetadata, // To tell if the registration has been touched and is not in sync anymore with database
  };
  if (initializeDatabaseMetadata) {
    newRegistration.inDatabase = validationFields; // To keep a copy of the saved state
  }
  return newRegistration;
};

export const getSessionSubscription = (registration, session) =>
  registration?.sessionsSubscriptions?.find((ss) => ss.session === session?._id);
