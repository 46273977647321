export const pick = (obj, props) => {
  // Create new object
  let picked = {};

  // Loop through props and push to new object
  for (let prop of props) {
    picked[prop] = obj[prop];
  }

  // Return new object
  return picked;
};

export const omit = (obj, props) => {
  // Create new object
  let picked = {...obj};

  // Loop through props and push to new object
  for (let prop of props) {
    delete picked[prop];
  }

  // Return new object
  return picked;
};

export const personName = (person: any): string =>
  person?.firstName?.length > 0
    ? person?.lastName?.length > 0
      ? `${person.firstName} ${person.lastName}`
      : person.firstName
    : "";

export const removeDuplicates = (array) => array?.filter((v, i, a) => a.indexOf(v) === i);
